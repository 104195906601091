import React from "react"
import styled from "styled-components"
import {
  BackLinkButton,
  LinkButton,
  LinkTextWithArrow,
} from "../../../components/Link"
import { media } from "../../../styles/media"

const StyledContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  ${() => media.sp`
    margin-inline: auto;
    flex-flow: column nowrap;
    align-items: stretch;
    row-gap: 1rem;
    width: 300px;
  `};
`
export default function Links() {
  return (
    <StyledContainer>
      <BackLinkButton to="/approach" text="戻る" />
      <LinkButton to="/product-search" text="商品検索" />
      <LinkTextWithArrow
        href="https://kurashi.online/"
        text="食物繊維の研究・取組をもっと見る"
        isExternal={true}
      />
    </StyledContainer>
  )
}
