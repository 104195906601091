import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import styled, { css } from "styled-components"

import { media } from "../../styles/media"

import SEO from "../../components/SEO"
// import CommitmentLinks from "../../components/CommitmentLinks"
import HeaderVisual from "../../components/HeaderVisual"
import Layout from "../../components/Layout"
import { Links } from "../../features/approach-fiber-page"

export const query = graphql`
  {
    strapiOurPolicyFiber {
      mainTitle
      mainSubTitles
      pageMainVisual {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fiberParagraph {
        id
        paraTitle
        paraContents
        paraMedia {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        fiberParaDetails {
          id
          miniTitle
          miniContents
          miniIMG {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
      iconMedia {
        id
        linkText
        linkName
        medias {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

const ContentsWrap = styled.div`
  padding: 50px 5% 100px;
  max-width: 1000px;
  margin: 0 auto;
  h3 {
    font-size: 1.32em;
    line-height: 1.62em;
    color: ${({ theme }) => theme.colors.primary.brown};
  }
`

const FiberSec = styled.div`
  margin-bottom: 50px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  .fsimg1 {
    width: 100%;
    p {
      padding: 10px 0;
      span {
        font-weight: bold;
        color: ${({ theme }) => theme.colors.primary.red};
      }
    }
  }
  .fsimg2 {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    .gatsby-image-wrapper {
      width: 50%;
    }
  }
  .fp {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    .fp1 {
      width: 48%;
      padding: 30px;
      background: ${({ theme }) => theme.colors.light.brown};
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-between;
      ${() =>
        media.sp(css`
          width: 100%;
        `)}
      .fp1a {
        width: 48%;
        h4 {
          font-weight: bold;
          color: ${({ theme }) => theme.colors.primary.brown};
        }
        p {
          font-size: 0.85em;
          padding: 10px 0;
        }
      }
      .gatsby-image-wrapper {
        width: 48%;
      }
    }
  }
`

const FiberLink = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-around;
  column-gap: 16px;
  margin-bottom: 50px;
  .fl1 {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 40%;
    position: relative;
    a {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    h5 {
      color: grey;
      font-size: 14px;
      margin-top: 15px;
      ${() =>
        media.sp(css`
          font-size: 12px;
        `)}
    }
    .gatsby-image-wrapper {
      width: 30%;
      ${() =>
        media.sp(css`
          width: 60%;
        `)}
    }
  }
`

const OurPolicyFiber = () => {
  const data = useStaticQuery(query)
  const { strapiOurPolicyFiber: strapiData } = data

  return (
    <Layout>
      <SEO>
        <title>食物繊維について｜フランソア</title>
        <meta name="description" content="		 		 			 			 			食物繊..." />
      </SEO>
      <HeaderVisual
        backImage={strapiData.pageMainVisual.childImageSharp.fluid}
        mainTitle={strapiData.mainTitle}
        mainText={strapiData.mainSubTitles}
      />

      <ContentsWrap>
        {strapiData.fiberParagraph.map((data, index) => {
          if (index !== 0) return null
          return (
            <FiberSec key={index}>
              <div className="fsimg1">
                <h3>{data.paraTitle}</h3>
                <p dangerouslySetInnerHTML={{ __html: data.paraContents }} />
              </div>
              <div className="fsimg2">
                {data.paraMedia && (
                  <Image fluid={data.paraMedia.childImageSharp.fluid} />
                )}
              </div>
              <div className="fp">
                {data.fiberParaDetails.map((detail, index) => (
                  <div className="fp1" key={index}>
                    <div className="fp1a">
                      <h4>{detail.miniTitle}</h4>
                      <p>{detail.miniContents}</p>
                    </div>
                    {detail.miniIMG && (
                      <Image fluid={detail.miniIMG.childImageSharp.fluid} />
                    )}
                  </div>
                ))}
              </div>
            </FiberSec>
          )
        })}
        <FiberLink>
          {strapiData.iconMedia.map((data, index) => (
            <div className="fl1" key={index}>
              <Image fluid={data.medias.childImageSharp.fluid} />
              <h5>{data.linkText}</h5>
              <Link to={"/" + data.linkName + "/"}></Link>
            </div>
          ))}
        </FiberLink>
        <Links />
        {/* <CommitmentLinks /> */}
      </ContentsWrap>
    </Layout>
  )
}

export default OurPolicyFiber
